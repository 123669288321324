<template>
  <b-card>
    <b-row>
      <b-col md="6">
        <b-card body-class="pb-50">
          <h6>Contratos Pendentes</h6>
          <h2 class="font-weight-bolder text-center mb-1">
            {{ totalPend }}
          </h2>
          <vue-apex-charts height="50" :options="statisticsOrder.chartOptions" :series="statisticsOrder.series" />
          <!-- chart -->
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card body-class="pb-50">
          <h6>Contratos Consolidados</h6>
          <h2 class="font-weight-bolder text-center mb-1">
            {{ totalCons }}
          </h2>
          <vue-apex-charts height="50" :options="statisticsOrder.chartOptions" :series="statisticsOrder.series" />
          <!-- chart -->
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import { BCard, BRow, BCol } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import useJwt from '@/auth/jwt/useJwt'

  const $barColor = '#f3f3f3'

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      VueApexCharts,
    },
    data() {
      return {
        totalRows: 1,
        totalCons: 1,
        totalPend: 1,
        isBusy: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 25,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        statisticsOrder: {
          series: [
            {
              name: '2020',
              data: [45, 85, 65, 45, 65],
            },
          ],
          chartOptions: {
            chart: {
              type: 'bar',
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            grid: {
              show: false,
              padding: {
                left: 0,
                right: 0,
                top: -15,
                bottom: -15,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '20%',
                startingShape: 'rounded',
                colors: {
                  backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                  backgroundBarRadius: 5,
                },
              },
            },
            legend: {
              show: false,
            },
            dataLabels: {
              enabled: false,
            },
            colors: [$themeColors.warning],

            xaxis: {
              labels: {
                show: false,
              },
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: {
              show: false,
            },
            tooltip: {
              x: {
                show: false,
              },
            },
          },
        },
      }
    },
    mounted() {
      this.carregarGrid()
      this.$root.$on('atualizarGraficos2', (item) => {
        this.userData = JSON.parse(localStorage.getItem('userData'))
        this.carregarGrid()
      })
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        if (this.userData.institutoSelecionado)
          useJwt
            .pesquisar('utils/GetAnalisesEmprestimo', {
              ...this.pesquisar,
              institutoSelecionado: this.userData.institutoSelecionado,
            })
            .then((response) => {
              if (response.data.status == 'erro') {
                this.isBusy = false
                console.error(response.data.message)
                this.$message.error(response.data.message)
                return
              }

              this.isBusy = false
              this.totalRows = response.data.total
              this.totalCons = response.data.totalCons
              this.totalPend = response.data.totalPend
            })
            .catch((error) => {
              console.error(error)
            })
      },
    },
  }
</script>
