<template>
  <!-- form -->
  <validation-observer ref="formSelecioneInstituto">
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group label="Selecione a Unidade Gestora" label-for="instituto">
            <validation-provider #default="{ errors }" name="Unidade Gestora" rules="required">
              <v-select-pt
                name="instituto"
                id="componentInstituto"
                inputId="selectInstituto"
                v-model="dados.institutoId"
                :options="instituto"
                :reduce="(option) => option.id"
                label="sigla"
                :loading="carregandoInstituto"
              ></v-select-pt>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" align="right">
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="submit" variant="primary" @click="confirmar">
            Confirmar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import { detectarNavegador } from '@/libs/utils'
  import { formatarData } from '@/libs/utils'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      formatarData,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        required,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        dados: {},
        instituto: [],
        carregandoInstituto: false,
      }
    },
    mounted() {
      this.carregarInstituto()
      this.carregarGrupoAcessoPorUser()
    },
    methods: {
      carregarGrupoAcessoPorUser() {
        if (this.userData.Instituto?.length > 1 && this.userData.role == 'admin') {
          useJwt
            .get(`cadastro/instituto/GetGrupoAcessoPorUser/${this.userData.id}`)
            .then((response) => {
              this.grupoAcessoUser = response.data.GrupoAcesso
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregarInstituto() {
        this.carregandoInstituto = true
        if (this.userData.role == 'admin') {
          useJwt
            .get(`cadastro/instituto/GetInstitutoPorUser/${this.userData.id}`)
            .then((response) => {
              if (this.userData.institutosVinculados.length > 1) {
                this.instituto = response.data.Instituto
              } else {
                this.instituto = this.userData.Instituto
              }
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(() => {
              this.carregandoInstituto = false
            })
        } else {
          useJwt
            .get(`cadastro/instituto`)
            .then((response) => {
              this.instituto = response.data
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(() => {
              this.carregandoInstituto = false
            })
        }
      },
      async confirmar(evento) {
        evento.preventDefault()
        this.$refs.formSelecioneInstituto.validate().then(async (success) => {
          if (success) {
            const dadosParaAtualizar = {
              userData: this.userData,
              institutoId: this.dados.institutoId,
              grupoAcessoId: null,
            }
            const updatedUserData = (await useJwt.post('auth/GetAtualizaUserData', dadosParaAtualizar)).data
            localStorage.setItem('userData', updatedUserData)
            this.$root.$emit('loadingMenu', true)

            await this.registarLogAcesso()
            await this.carregaLogoSistema(this.dados.institutoId)

            this.atualizarInformacoesTelaPrincipal()
          }
        })
      },
      async registarLogAcesso() {
        try {
          let navegadorUtilizado = detectarNavegador()

          const dadosLogAcesso = {
            institutoId: this.dados.institutoId,
            email: this.userData.email,
            ip: window.globalIP,
            descricao: navegadorUtilizado,
          }
          useJwt.post('auth/LogAcesso', dadosLogAcesso)
        } catch (error) {
          console.error(error)
        }
      },

      atualizarInformacoesTelaPrincipal() {
        this.$root.$emit('atualizarItemsMenu', true)
        this.$emit('selecionarInstitutoEvent', true)
        this.$root.$emit('selecionarInstitutoNavBar', this.dados.institutoId)
        this.$root.$emit('atualizarLogoMenu', true)
        this.$root.$emit('atualizarGraficos3', true)
        this.$root.$emit('atualizarGraficos2', true)
        this.$root.$emit('atualizarGraficos1', true)
        this.$router.go()
      },
      async carregaLogoSistema(institutoId) {
        if (!institutoId)
          return localStorage.setItem(
            'images',
            JSON.stringify({
              logo: null,
              logoMarca: null,
              logoRelatorio: null,
              logoCalculadora: null,
            }),
          )

        useJwt
          .get(`auth/carregarLogosSistema/${institutoId}`)
          .then((response) => {
            const logos = {
              logo: response.data.logo || null,
              logoMarca: response.data.logoMarca || null,
              logoRelatorio: response.data.logoRelatorio || null,
              logoCalculadora: response.data.logoCalculadora || null,
            }

            localStorage.setItem('images', JSON.stringify(logos))
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
